
import Base from "@/views/layout/Base.vue";
import { fund, yearDic } from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilter = ref();
    const groupSum = (obj) =>
      obj.reduce((groups, { 市级: city, "安排金额（万元）": amount }) => {
        var groupFound = groups.find((arrItem) => city === arrItem["市级"]);
        if (groupFound) {
          if (typeof groupFound["安排金额（万元）"] != "number")
            groupFound["安排金额（万元）"] = parseFloat(
              groupFound["安排金额（万元）"]
            );
          groupFound["安排金额（万元）"] += parseFloat(amount);
        } else {
          //不要直接在原来的对象修改，新建对象
          var newGroup = {
            市级: city,
            "安排金额（万元）": parseFloat(amount),
          };
          groups.push(newGroup);
        }
        return groups;
      }, []);

    const search = () => {
      isLoading.value = true;
      setTimeout(() => {
        fundFilter.value = groupSum(
          fund.filter(
            (p) =>
              filter.year == null ||
              filter.year == "" ||
              filter.year.toString() == p.资金安排年度
          )
        );
        isLoading.value = false;
      }, 200);
    };
    onMounted(() => {
      search();
      watch(filter, () => {
        search();
      });
    });

    const filter = reactive({
      year: "2021",
    });
    return {
      isLoading,
      yearDic,
      filter,
      fundFilter,
    };
  },
});
